<template lang="">
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus title="Referral Summary" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            title="Referral"
            :value="` ${indexMetaData.summary.total_referral}`"
            variant="blue"
          />
          <SummaryCard
            title="Earning"
            :value="`${currencySymbol} ${indexMetaData.summary.total_earning}`"
            variant="green"
          />
          <SummaryCard
            title="Pending"
            :value="`${currencySymbol} ${indexMetaData.summary.total_pending}`"
            variant="orange"
          />
        </div>
      </div>
      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem :text="`#${item.id}`" :truncate="-5" />
                <FSTableRowItem
                  :text="item.invitee_user.full_name"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.invitee_user.id },
                  }"
                  target="_blank"
                />

                <FSTableRowItem
                  :text="
                    getFormattedDateTime(
                      item.created_at,
                      'D MMM, YYYY hh:mm:ss a'
                    )
                  "
                />

                <FSTableRowItem>{{ item.completed_trips }}</FSTableRowItem>
                <FSTableRowItem>{{ item.trips_left }}</FSTableRowItem>
                <FSTableRowItem :text="`${currencySymbol} ${item.earning}`" />

                <FSTableRowItem>
                  <x-status
                    :text="item.is_completed ? `Completed` : `Pending`"
                    :variant="getItemVarient(item.is_completed)"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.invitee_user.full_name"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.invitee_user.id },
                  }"
                  target="_blank"
                />
                <FSTableRowItem :text="`${currencySymbol} ${item.earning}`" />

                <FSTableRowItem>
                  <x-status
                    :text="item.is_completed ? `Completed` : `Pending`"
                    :variant="getItemVarient(item.is_completed)"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Referral ID</div>
                    <div class="col-span-5 right-text">
                      #{{ $truncate(item.id, -5) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Sign up Date</div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.created_at,
                          'D MMM, YYYY hh:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Trips Taken</div>
                    <div class="col-span-5 right-text">
                      {{ item.completed_trips }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Trips left</div>
                    <div class="col-span-5 right-text">
                      {{ item.trips_left }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>
<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import riderConfig from '@/config/rider.config'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'VoucherIndex',
  data: function() {
    return {
      windowWidth: window.innerWidth,
      id: '',
      indexDataEndpoint: '',
      details: {},
      indexMetaData: {
        summary: {
          total_referral: 0,
          total_earning: 0,
          total_pending: 0,
        },
      },
      qso: { append: '', prepend: '?' },
      fstId: 'referralSummary',
      tableHeaders: [
        { text: 'Referral ID', width: '10%', sort: 'referral_id' },
        { text: 'Referred To', width: '10%', sort: 'referred_to' },
        { text: 'Sign up Date', width: '20%', sort: 'sign_up_date' },
        { text: 'Trips Taken', width: '10%', sort: 'trips_taken' },
        { text: 'Trips left', width: '10%', sort: 'trips_left' },
        { text: 'Amount', width: '10%', sort: 'amount' },
        { text: 'Status', width: '15%', sort: 'status' },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Referred To', width: '35%', sort: 'referred_to' },
        { text: 'Amount', width: '35%', sort: 'amount' },
        { text: 'Status', width: '20%', sort: 'status' },
      ],
      opened: [],
      filterOptions: [
        {
          key: 'referral_type',
          type: 'checkbox',
          input: [
            { text: 'As Inviter', value: 'as_inviter' },
            { text: 'As Invitee', value: 'as_invitee' },
          ],
          title: 'Referral Type',
        },
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Pending', value: 'pending' },
            { text: 'Completed', value: 'completed' },
          ],
          title: 'Status',
        },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
    }
  },
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    XStatus,
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = riderConfig.api.referralHistory(this.id)
    this.getRiderAllReferralDetails()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async getRiderAllReferralDetails() {
      let { data } = await this.$http.get(this.indexDataEndpoint)
      //   console.log('referral-data', data)
      if (data) {
        this.details = data
      }
    },
    getLeftTrip(target, complete) {
      return target - complete
    },
    getItemVarient(status) {
      console.log('item-status', status)
      if (status === true) return 'green'
      else return 'orange'
    },
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
