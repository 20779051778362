<template>
  <div>
    <ReferralList></ReferralList>
  </div>
</template>

<script>
import ReferralList from '@/composites/referrals/index/Index.vue'

export default {
  name: 'ViewRiderUserReferrals',
  components: {
    ReferralList,
  },
}
</script>
