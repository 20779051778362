<template>
  <div>
    <VoucherList></VoucherList>
  </div>
</template>
<script>
import VoucherList from '@/composites/voucher/index/Index.vue'
export default {
  components: { VoucherList },
}
</script>
<style lang="scss"></style>
